import React from "react"
import PropTypes from "prop-types"

class DebugInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: props.room,
      player: props.player,
      user: props.user
    };
  }

  render () {
    if (this.state.user)
    {
      return (
        <div className="mt-5">
          <hr />
          <h3>Debug Info</h3>
          <p className="text-muted">This information appears only for admin users.</p>
          <h4>Room</h4>
          <ul>
            <li><strong>started_at: </strong>{this.state.room.started_at ? this.state.room.started_at : '-'}</li>
            <li><strong>ended_at: </strong>{this.state.room.ended_at ? this.state.room.ended_at : '-'}</li>
          </ul>
          <h4>Player</h4>
          <ul>
            <li><strong>name: </strong>{this.state.player.name ? this.state.player.name : '-'}</li>
          </ul>
          <h4>User</h4>
          <ul>
            <li><strong>email: </strong>{this.state.user.email ? this.state.user.email : '-'}</li>
          </ul>
        </div>
      );
    }
    return null;
  }
}

DebugInfo.propTypes = {
  room: PropTypes.object,
  player: PropTypes.object,
  user: PropTypes.object
};

export default DebugInfo
