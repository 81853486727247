import React from "react"
import PropTypes from "prop-types"
import DebugInfo from "./DebugInfo"
import GamePanel from "./GamePanel"
import CategoriesGamePanel from "./CategoriesGamePanel"
import consumer from "../channels/consumer"

class Room extends React.Component {
  constructor(props) {
    super(props);

    let self = this;

    this.state = {
      room: this.props.room,
      room_channel: consumer.subscriptions.create(
      {
        channel: "RoomsChannel",
        room_id: self.props.room.access_code
      },
      {
        connected() {
          fetch(self.props.room_url)
            .then(response => response.json())
            .then(data => {
              self.setState({ room: data });
            });
        },

        disconnected() {
          console.log("Disconnected from " + self.props.room.access_code)
        },

        received(data) {
          console.log("RoomsChannel Data Received:")
          console.log(data);
          self.setState({
            room: data
          });
        }
      })
    };
  }

  // Messages may be received out of order
  // If an older message is recieved do not update
  shouldComponentUpdate(nextProps, nextState) {
    let should = nextState.room.updated_at > this.state.room.updated_at;
    if (!should)
    {
      console.log("Messages recieved out of order!");
    }
    console.log("state.room.updated_at: " + this.state.room.updated_at);
    console.log("nextState.room.updated_at: " + nextState.room.updated_at);
    console.log("shouldComponentUpdate: " + should);
    return should;
  }

  render () {
    let panel;
    <h1>WTF!</h1>
    if(this.state.room.game_data.type == 'AllPlayGame') {
      return <GamePanel
        room={this.state.room}
        player={this.props.player}
        room_channel={this.state.room_channel} />
    }
    else if (this.state.room.game_data.type == 'CategoriesGame') {
        return <CategoriesGamePanel
          room={this.state.room}
          player={this.props.player}
          room_channel={this.state.room_channel} />
    }
    else
    {
      // hopefully this never happens
      return <h1>Room has no game!</h1>
    }
  }
}

Room.propTypes = {
  player: PropTypes.object,
  room: PropTypes.object,
  user: PropTypes.object
};

export default Room

function NameEmailHeader(props)
{
  if(props.name && props.email)
  {
    return (
      <div className='mb-3'>
        <div className='text-muted'>Playing as:</div>
        <p className=''>{props.name} ({props.email})</p>
      </div>
    )
  }
  else
  {
    if(props.name)
    {
      return (
        <div className='mb-3'>
          <div className='text-muted'>Playing as:</div>
          <p className=''>{props.name}</p>
        </div>
      )
    }
    if(props.email)
    {
      return (
        <div className='mb-3'>
          <div className='text-muted'>Playing as:</div>
          <p className=''>({props.email})</p>
        </div>
      )
    }
  }
}

function RoomTitle(props) {
  if(props.room_name)
  {
    return <h1>{props.room_name}</h1>
  }
}
