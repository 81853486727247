import React from "react"
import PropTypes from "prop-types"

class GameButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_id: `${this.props.value}AnswerModal`,
      modal_labeled_by: `${this.props.value}AnswerModalTitle`,
      close_button_id: `${this.props.value}AnswerModalCloseButton`
    };

    this.submitAnswer = this.submitAnswer.bind(this);
  }

  getVisible(props)
  {
    return !!props.room.started_at &&
    !props.room.ended_at &&
    props.number < props.room.game_data.answer_count &&
    props.room.game_data.question_open &&
    !props.answered;
  }

  getDisabled(props)
  {
    return false; // !props.room.game_data.question_open || props.answered;
  }

  submitAnswer(answer) {
    let data = {
        answer:
        {
          'choice': answer,
          'question_no': this.props.room.game_data.question_no,
          'room_access_code': this.props.room.access_code,
          'player_id': this.props.player.id
        }
      };

    this.props.room_channel.send(data);
    this.props.onAnswer(answer);
  }

  render () {
    let visible = this.getVisible(this.props);
    let disabled = this.getDisabled(this.props);
    if (visible) {
      return (
        <div className='button'>
          <button type='button'
            data-toggle='modal'
            data-target={`#${this.state.modal_id}`}
            className={`btn btn-block btn-lg btn-${this.props.color}`}
            disabled={disabled}>
            <h1>{this.props.value}</h1>
          </button>

          <div className="modal fade"
            id={this.state.modal_id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby={this.state.modal_labeled_by}
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered"
                 role="document">
              <div className="modal-content bg-primary">
                <div className='modal-body'>
                  <div className='text-center text-white'>You selected</div>
                  <div
                    className={`display-4 font-weight-bold text-center text-${this.props.text_color}`}
                    id={this.state.modal_labeled_by}>
                    {this.props.value}
                  </div>
                  <button
                    type="button"
                    className="submit btn btn-lg btn-warning btn-block font-weight-bold mt-3"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.submitAnswer.bind(this, this.props.value)}>LOCK IT IN</button>
                  <div className='text-center mt-4 mb-3'>
                    <button type="button"
                            id={this.state.close_button_id}
                            className="cancel btn btn-danger font-weight-bold px-4 mx-"
                            data-dismiss="modal"
                            aria-label="Close">
                            CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

GameButton.propTypes = {
  room: PropTypes.object,
  player: PropTypes.object,
  value: PropTypes.string,
  room_channel: PropTypes.object,
  number: PropTypes.number
};

export default GameButton
