import React from "react"

class CenteredDiv extends React.Component {
  render () {
    let className

    if(this.props.height)
    {
      className = 'h-' + this.props.height
    }
    return (
      <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translateY(-50%)'}} className={className}>
        <div style={{position: 'relative', left: '-50%'}} className={className}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default CenteredDiv
