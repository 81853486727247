import React from "react"
import PropTypes from "prop-types"
import GameButton from "./GameButton"

class GamePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.getAnswerFromProps(props)
    }
    this.handleAnswer = this.handleAnswer.bind(this);
  }

  getAnswerFromProps(props)
  {
    let answer = null;
    if (props.room.answer_data && props.room.answer_data[props.player.id])
    {
      answer = props.room.answer_data[props.player.id]
    }
    return answer;
  }

  handleAnswer(value)
  {
    this.setState((state, props) => {
      return {answer: value};
    });
  }

  componentDidUpdate(prevProps) {
    /* if advancing question */
    if(prevProps.room.game_data.question_no != this.props.room.game_data.question_no)
    {
      this.setState((state, props) => {
        return {answer: null};
      });

      this.closeModal();
    }
    if(prevProps.room.game_data.answer_count != this.props.room.game_data.answer_count)
    {
      this.closeModal();
    }

    if(!this.props.room.game_data.question_open || !!this.props.room.ended_at)
    {
      this.closeModal();
    }
  }

  // kinda janky but it will suffice
  // the better way to do this might be to include the bootstrap js files
  //   and call the close event
  // this is realatively safe, however and will only really break if the modals
  //   radically change from bootstrap convention
  closeModal()
  {
    // finds any open modal and closes it
    // can safely run even if there are no open modals
    $("div.modal.show").find(".close").click();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  submitAnswer()
  {
    // finds any open modal and submits it
    // can safely run even if there are no open modals
    $("div.modal.show").find(".submit").click();
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  getButtonsVisible(props)
  {
    return !!props.room.started_at &&
    !props.room.ended_at &&
    props.room.game_data.question_open &&
    !this.state.answer;
  }

  render () {
    let visible = this.getButtonsVisible(this.props);

    return (
      <React.Fragment>
        <StatusMessage room={this.props.room} player={this.props.player} answer={this.state.answer} />
        <Buttons
          visible={visible}
          room={this.props.room}
          room_channel={this.props.room_channel}
          player={this.props.player}
          handleAnswer={this.handleAnswer}
          answered={!!this.state.answer} />
      </React.Fragment>
    );
  }
}

GamePanel.propTypes = {
  room: PropTypes.object,
  player: PropTypes.object,
  room_channel: PropTypes.object
};

export default GamePanel

function StatusMessage(props) {
  let team_name;

  if(props.player.team_name)
  {
    team_name = <div className='text-center'>{props.player.team_name}</div>
  }

  if(props.room.started_at)
  {
    if(props.room.ended_at)
    {
      return(
        <React.Fragment>
          {team_name}
          <CenteredDiv>
            <div className='text-center mb-4'>The Event has<br />ended.</div>
            <div className='text-center'>Have a great<br />day!</div>
          </CenteredDiv>
        </React.Fragment>
      );
    }
    else
    {
      if(props.room.game_data.question_open)
      {
        if(!!props.answer)
        {
          return(
            <React.Fragment>
              {team_name}
              <CenteredDiv>
                  <div className='mb-2 text-center'>Your answer</div>
                  <h1 className='text-center font-weight-bold text-muted'>{props.answer}</h1>
                  <div className='text-center'>has been</div>
                  <div className='text-center'>received</div>
              </CenteredDiv>
            </React.Fragment>
          );
        }
        else
        {

        }
      }
      else
      {
        return(
          <React.Fragment>
            {team_name}
            <CenteredDiv>
              <div className='text-center'>Standby to<br />Answer Next<br />Question.</div>
            </CenteredDiv>
          </React.Fragment>
        );
      }
    }
  }
  else
  {
    return(
      <div className='d-flex flex-column justify-content-between h-100 pb-3'>
        {team_name}
        <PlayerGreeting name={props.player.name} email={props.player.email} />
        <div className='text-center'>
          You've<br />successfully<br />logged in!
        </div>
        <div className='text-center'>
          Standby to<br />participate!
        </div>
      </div>
    );
  }
  return null;
}

function CenteredDiv(props){
  return (
    <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translateY(-50%)'}}>
      <div style={{position: 'relative', left: '-50%'}}>
        {props.children}
      </div>
    </div>
  )
}

function PlayerGreeting(props) {
  if(props.name) {
    return (
      <div className='text-center'>
        <div>Hi</div>
        <div>{props.name}</div>
      </div>
    );
  } else {
    return (
      <div className='text-center'>
        <div>Hi</div>
        <div>{props.email}</div>
      </div>
    );
  }
}

function TeamTitle(props) {
  if(props.name) {
    return (
      <div className='text-center'>
        <div>You're on team</div>
        <div>{props.name}</div>
      </div>
    )
  } else {
    return null;
  }
}

function Buttons(props) {
  if (props.visible) {

    return(
      <div className='d-flex flex-column justify-content-around h-100'>
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='A'
          color='success'
          text_color='white'
          number={0}
          answered={props.answered}
        />
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='B'
          color='success'
          text_color='white'
          number={1}
          answered={props.answered}
        />
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='C'
          color='success'
          text_color='white'
          number={2}
          answered={props.answered}
        />
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='D'
          color='success'
          text_color='white'
          number={3}
          answered={props.answered}
        />
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='E'
          color='success'
          text_color='white'
          number={4}
          answered={props.answered}
        />
        <GameButton
          room={props.room}
          room_channel={props.room_channel}
          player={props.player}
          onAnswer={props.handleAnswer}
          value='F'
          color='success'
          text_color='white'
          number={5}
          answered={props.answered}
        />
      </div>
    );
  } else {
    return null;
  }
}
