import React from "react"
import PropTypes from "prop-types"
import CenteredDiv from "./CenteredDiv"

class CategoriesGamePanel extends React.Component {
  constructor(props) {
    super(props);
    this.answer = this.getAnswerFromProps(props);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  getAnswerFromProps(props)
  {
    let answer = null;
    if (props.room.answer_data && props.room.answer_data[props.player.id])
    {
      answer = props.room.answer_data[props.player.id]
    }
    return answer;
  }

  excluded(props)
  {
    let excluded = false;
    let player_ids = props.room.game_data.except_players;
    let team_ids = props.room.game_data.except_teams;

    if (player_ids)
    {
      for(var i = 0; i < player_ids.length; i++)
      {
        excluded = excluded || player_ids[i] == props.player.id
      }
    }

    if (team_ids)
    {
      for(var i = 0; i < team_ids.length; i++)
      {
        excluded = excluded || team_ids[i] == props.player.team_id
      }
    }

    return excluded;
  }

  submitAnswer() {
    let data = {
        answer:
        {
          'choice': 'X',
          'question_no': 0,
          'room_access_code': this.props.room.access_code,
          'player_id': this.props.player.id
        }
      };

    this.answer = 'X';
    this.forceUpdate();

    this.props.room_channel.send(data);
  }

  render () {
    let content;
    let team_name;

    if(this.props.player.team_name)
    {
      team_name = <div className='text-center' style={{position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)'}}>{this.props.player.team_name}</div>
    }

    if(this.props.room.ended_at)
    {
      content =
        <React.Fragment>
          {team_name}
          <div>
            <div className='text-center mb-4'>The Event has<br />ended.</div>
            <div className='text-center'>Have a great<br />day!</div>
          </div>
        </React.Fragment>
    }
    else
    {
      if(this.props.room.started_at)
      {
        if(this.props.room.game_data.player_selected ||
          this.excluded(this.props) ||
          !!this.answer ||
          !this.props.room.game_data.question_open)
        {
          content =
            <React.Fragment>
              {team_name}
              <div className='categories-button-container'>
                <div className='categories-button disabled'>
                  <div className="categories-button-text">
                    Stand By
                  </div>
                </div>
              </div>
            </React.Fragment>
        }
        else
        {
          content =
            <React.Fragment>
              {team_name}
              <div className='categories-button-container' onClick={this.submitAnswer.bind(this)}>
                <div className='categories-button active'>
                  <div className="categories-button-text">
                    Ring-In!
                  </div>
                </div>
              </div>
            </React.Fragment>
        }
      }
      else // !started_at
      {
        content =
          <React.Fragment>
            {team_name}
            <CenteredDiv>
                <div className='text-center mb-4'>
                  Thanks for<br />logging in!
                </div>
                <div className='text-center'>
                  Stand by<br />for the<br />game!
                </div>
            </CenteredDiv>
          </React.Fragment>
      }
    }

    this.answer = null;

    return (
      <div className='d-flex flex-column justify-content-around h-100'>
        {content}
      </div>
    );
  }
}

CategoriesGamePanel.propTypes = {
  room: PropTypes.object,
  player: PropTypes.object,
  room_channel: PropTypes.object
};

export default CategoriesGamePanel
